import React, { Component, Fragment } from 'react';
import { Field } from 'redux-form';
import '../ppvmodal.css';
import { Input, Message, Error } from '../../../../components';
import MessageUs from '../../../../components/MessageUs';
import Parser from 'html-react-parser';
import {
    INVALID_CODE_ERROR,
    CODE_EXPIRED_ERROR,
    SEND_CODE_ERROR,
    MAX_ATTEMPT_ERROR,
    VALIDATE_CODE_ERROR,
    TELSTRAPRE_NUMBER_ERROR,
    TELLEGPRE_NUMBER_ERROR,
    TELSTRAPOST_NUMBER_ERROR,
    TELLEGPOST_NUMBER_ERROR,
    BOOSTLEG_NUMBER_ERROR,
    TEM_NUMBER_ERROR,
    SAMEPRE_NUMBER_ERROR,
    NONTPOST_NUMBER_ERROR,
    PORT_NOT_ALLOWED_ERROR,
    PORT_GENERIC_FATAL_ERROR,
    MIS_ORDER_INPROGRESS
} from '../../../../adapters/errorCode';
import { isEmpty, NUMBER_PATTERN, AUS_NUMBER } from '../../../../adapters/validations';
import { nextPage, focusOnElement, getBrandDetails, applyBrandDataInTemplate, getBrandNameFromNonTPostStatus, getAbleSpriteSheet } from '../../../../adapters/utils';
import { BRANDS } from '../../../../adapters/constants';
import PortingLock from '../../../../assets/img/svg/lock.svg';
import ErrorIcon from '../../../../assets/img/svg/error-icon-blue-lg.svg';
import FatalErrorIcon from '../../../../assets/img/svg/fatal-error-icon.svg';
import store from '../../../../store';
import { Spinner } from '../../../../components/Spinner';
import SpinningButton from '../../../../components/SpinningButton/SpinningButton';
import { updateDataLayerObject, createDataLayerPage, addDataLayerEventInfo } from '../../../../adapters/analytics-utils';
import { ActionButton, IconButton, MessageSection, TextStyle } from '@able/react';

const errorCodeArray = [MAX_ATTEMPT_ERROR, SEND_CODE_ERROR, VALIDATE_CODE_ERROR, PORT_NOT_ALLOWED_ERROR, PORT_GENERIC_FATAL_ERROR];
const ableSpriteSheet = getAbleSpriteSheet();

class TransferNumber extends Component {
    static getDerivedStateFromProps(props, state) {
        const storeObject = store.getState();

        const {
            validations: { isPrePortNumberVerified, isSmsCodeVerified, isPortingInProgress, isVerifySmsInProgress }
        } = storeObject.welcome;
        const { resetErrors } = props.actions;
        const {
            errors: { hasError = false, errorCode = null }
        } = storeObject.welcome;

        const {
            toggleModal,
            actions: { updateStepperCustomData }
        } = props;

        const update = {};
        if (
            !isPrePortNumberVerified &&
            !isPortingInProgress &&
            hasError &&
            (errorCode === TELSTRAPRE_NUMBER_ERROR ||
                errorCode === TELLEGPRE_NUMBER_ERROR ||
                errorCode === TELSTRAPOST_NUMBER_ERROR ||
                errorCode === TELLEGPOST_NUMBER_ERROR ||
                errorCode === BOOSTLEG_NUMBER_ERROR ||
                errorCode === TEM_NUMBER_ERROR ||
                errorCode === SAMEPRE_NUMBER_ERROR ||
                errorCode === NONTPOST_NUMBER_ERROR)
        ) {
            update.verifyNumber = '';
            update.showScreen = 'verify number';
        }
        if (!isPrePortNumberVerified && isPortingInProgress && !isVerifySmsInProgress && !hasError) {
            update.showScreen = 'resend sms';
            // **** here's the timeout ****
        }

        if (isPrePortNumberVerified && isVerifySmsInProgress && !hasError && !isSmsCodeVerified) {
            update.showScreen = 'resend sms';
        }

        if (isPrePortNumberVerified && !isSmsCodeVerified && !isVerifySmsInProgress && !hasError) {
            setTimeout(() => {
                update.showScreen = 'verify sms';
                update.otp = '';
            }, 8000);
        }
        if (isSmsCodeVerified) {
            toggleModal && toggleModal();
            nextPage(updateStepperCustomData);
        }
        if (state.showScreen === 'verify sms' && hasError && errorCodeArray.indexOf(errorCode) !== -1) {
            update.showScreen = 'show error';
        }
        if (hasError && errorCodeArray.indexOf(errorCode) !== -1) {
            update.showScreen = 'show error';
        } else if (hasError && errorCode === CODE_EXPIRED_ERROR) {
            update.showScreen = 'code expired';
        } else if (errorCode === CODE_EXPIRED_ERROR && isPrePortNumberVerified) {
            resetErrors();
        } else if (hasError && errorCode === INVALID_CODE_ERROR) {
            update.showScreen = 'verify sms';
            update.otp = '';
        }
        if (isPortingInProgress) {
            resetErrors();
            update.otp = '';
        }
        return update;
    }

    constructor(props) {
        super(props);
        this.state = { modalOpen: true };
        this.state = {
            showScreen: 'verify number',
            otp: '',
            verifyNumber: '',
            isResendDisabled: false,
            errors: {}
        };
        this.changeScreen = this.changeScreen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resendSmsCode = this.resendSmsCode.bind(this);
        this.getNewSmsCode = this.getNewSmsCode.bind(this);
        this.verifySmsCode = this.verifySmsCode.bind(this);
        this.checkMobileNumber = this.checkMobileNumber.bind(this);
        this.disableLink = this.disableLink.bind(this);
        this.renderVerifyNumber = this.renderVerifyNumber.bind(this);
        this.handleEnterClick = this.handleEnterClick.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidUpdate() {
        const storeObject = store.getState();
        const {
            errors: { hasError, errorCode }
        } = storeObject.welcome;

        if (
            hasError &&
            (errorCode === TELSTRAPRE_NUMBER_ERROR ||
                errorCode === TELLEGPRE_NUMBER_ERROR ||
                errorCode === TELSTRAPOST_NUMBER_ERROR ||
                errorCode === TELLEGPOST_NUMBER_ERROR ||
                errorCode === TEM_NUMBER_ERROR ||
                errorCode === SAMEPRE_NUMBER_ERROR ||
                errorCode === NONTPOST_NUMBER_ERROR)
        ) {
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'This is a Telstra number');
            focusOnElement('#error-description');
        }
        if (hasError && errorCode === BOOSTLEG_NUMBER_ERROR) {
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'Unable to process request');
            focusOnElement('#error-description');
        }
        if (hasError && errorCode === INVALID_CODE_ERROR) {
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'Incorrect SMS code entered');
        }
    }

    render() {
        const storeObject = store.getState();

        const { handleSubmit, actions, toggleModal } = this.props;

        const {
            validations: { isPortingInProgress, isVerifySmsInProgress }
        } = storeObject.welcome;

        const disableButton = isPortingInProgress;
        const {
            verifyNumberform: { verifyNumbertitle, verifyNumberPara, verifyNumberField, buttonLabel, codeSendingText }
        } = this.props;
        const {
            verifySmsform: { ppvMessenger, verifySmsTitle, verifySmsResentTitle, verifySmsPara, verifySmsField, resendLink, faqText, codeVerifyingText, verifySmsButtonLabel }
        } = this.props;
        const verifyNumberProps = { verifyNumbertitle, verifyNumberPara, verifyNumberField, buttonLabel, handleSubmit, actions, toggleModal, isPortingInProgress, disableButton, faqText };
        const verifySms = { ppvMessenger, verifySmsTitle, verifySmsResentTitle, verifySmsPara, verifySmsField, resendLink, faqText, toggleModal, handleSubmit, verifySmsButtonLabel };
        let resendSmsScreenProps = codeSendingText;

        if (!isPortingInProgress && isVerifySmsInProgress) {
            resendSmsScreenProps = codeVerifyingText;
        }

        const {
            errors: { hasError, errorCode = null }
        } = storeObject.welcome;
        let errorScreenProps = {};
        if (hasError) {
            const errors = this.fetchErrors();
            errorScreenProps = {
                ...errors,
                handleSubmit
            };
        }
        if (this.state.showScreen === 'verify sms' && hasError && errorCodeArray.indexOf(errorCode) !== -1) {
            this.changeScreen('show error');
        }
        return (
            <div className="modal-content ppv-modal-content">
                {this.state.showScreen === 'verify number' && this.verifyNumberScreen(verifyNumberProps)}
                {this.state.showScreen === 'verify sms' && this.verifySmsScreen(verifySms)}
                {this.state.showScreen === 'show error' && this.errorScreen(errorScreenProps)}
                {this.state.showScreen === 'code expired' && this.codeExpiredErrorScreen(errorScreenProps)}
                {this.state.showScreen === 'resend sms' && this.resendSmsScreen(resendSmsScreenProps)}
            </div>
        );
    }

    fetchErrors() {
        const storeObject = store.getState();

        const {
            errors: { errorCode }
        } = storeObject.welcome;

        const {
            formErrors: { maxAttemptsError, codeExpiredError, sendCodeError, validateCodeError, portError, portgenericFatalError, misOrderInprogressError }
        } = this.props;
        let errorScreenProps = {};
        if (errorCode === MAX_ATTEMPT_ERROR) {
            errorScreenProps = maxAttemptsError;
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'You’ve run out of attempts to correctly enter your SMS code.');
        } else if (errorCode === CODE_EXPIRED_ERROR) {
            errorScreenProps = codeExpiredError;
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'You’ve run out of attempts to correctly enter your SMS code.');
        } else if (errorCode === SEND_CODE_ERROR) {
            errorScreenProps = sendCodeError;
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'Something went wrong');
        } else if (errorCode === VALIDATE_CODE_ERROR) {
            errorScreenProps = validateCodeError;
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'Unable to process request');
        } else if (errorCode === PORT_NOT_ALLOWED_ERROR) {
            errorScreenProps = portError;
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'You’ve run out of attempts to correctly enter your SMS code.');
        } else if (errorCode === PORT_GENERIC_FATAL_ERROR) {
            errorScreenProps = portgenericFatalError;
            addDataLayerEventInfo('error', 'Validation', 'unavailable', 'Unable to process request');
        } else if (errorCode === MIS_ORDER_INPROGRESS) {
            errorScreenProps = misOrderInprogressError;
        }
        return errorScreenProps;
    }

    verifySmsCode(values, dispatch, props) {
        const storeObject = store.getState();
        const { validateSmsCode } = props.actions;
        const { portingNumber = null } = storeObject.welcome;
        const {
            verifySmsform: { verifySmsField }
        } = props;
        const payload = {
            data: {
                mobileNumber: portingNumber,
                verificationCode: values.otp
            }
        };

        const errors = {};

        // INLINE VALIDATION
        if (values && !values.otp) {
            errors.otp = verifySmsField.errors.required;
            addDataLayerEventInfo('error', 'Inline', 'unavailable', verifySmsField.errors.required);
        } else if (values.otp.length < 6 || !NUMBER_PATTERN.test(values.otp)) {
            errors.otp = verifySmsField.errors.pattern;
            addDataLayerEventInfo('error', 'Inline', 'unavailable', verifySmsField.errors.patter);
        }
        this.setState({ errors });

        if (!isEmpty(values.otp) && values.otp.length === 6 && NUMBER_PATTERN.test(values.otp)) {
            this.props.clearFields(false, false, 'otp');
            validateSmsCode(payload);
        }
    }

    checkMobileNumber(values, dispatch, props) {
        const { validatePrePortVerfication } = props.actions;
        const mobileNumber = values.verifyNumber;
        const {
            verifyNumberform: { verifyNumberField }
        } = props;
        const errors = {};
        // INLINE VALIDATION
        if (values && !mobileNumber) {
            errors.verifyNumber = verifyNumberField.errors.required;
            addDataLayerEventInfo('error', 'Inline', 'unavailable', verifyNumberField.errors.required);
        }
        if (values && mobileNumber && mobileNumber.length < 10) {
            errors.verifyNumber = verifyNumberField.errors.pattern;
            addDataLayerEventInfo('error', 'Inline', 'unavailable', verifyNumberField.errors.pattern);
        } else if (values && mobileNumber && !AUS_NUMBER.test(mobileNumber)) {
            errors.verifyNumber = verifyNumberField.errors.pattern;
            addDataLayerEventInfo('error', 'Inline', 'unavailable', verifyNumberField.errors.pattern);
        }

        this.setState({ errors });
        if (!isEmpty(mobileNumber) && !errors.verifyNumber) {
            this.props.clearFields(false, false, 'verifyNumber');
            validatePrePortVerfication(mobileNumber);
        }
    }

    goback() {
        this.props.change('selectedActivationFlow', null);
    }

    handleChange(e) {
        const { name } = e.target;
        this.setState({ errors: { [name]: '' } });
        const { resetErrors } = this.props.actions;
        resetErrors();
    }

    changeScreen(screenName) {
        this.setState({ showScreen: screenName });
    }
    disableLink() {
        this.setState({
            showScreen: 'resend sms'
        });
        // **** here's the timeout ****
        setTimeout(() => this.setState({ showScreen: 'verify sms' }), 10000);
    }

    handleEnterClick(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            document.querySelector('#verifyButton') && document.querySelector('#verifyButton').click();
            document.querySelector('#smsButton') && document.querySelector('#smsButton').click();
        }
    }

    verifyNumberScreen(verifyNumber) {
        const { verifyNumbertitle, verifyNumberPara, verifyNumberField, buttonLabel, handleSubmit } = verifyNumber;
        const { telstraNumberNewErrorTitle, telstraNumberErrorTitle, LegacyErrorTitle } = verifyNumberField.errors;
        const storeObject = store.getState();
        const {
            validations: { isPortingInProgress },
            errors: { hasError, errorCode, errorHttpStatus }
        } = storeObject.welcome;
        updateDataLayerObject(createDataLayerPage('Verify Your Number'));
        let title = '';
        let preInfo = '';
        let postInfo = '';
        let message = '';
        let text = '';
        const { brandName } = getBrandDetails();
        if (hasError && errorCode === TELSTRAPRE_NUMBER_ERROR) {
            title = telstraNumberNewErrorTitle;
            message = 'Customer has entered a Telstra mobile number in the Pre-Paid port flow';
            preInfo = 'Input error. ';
            postInfo = 'You can’t transfer a Telstra prepaid mobile number.';
        } else if (hasError && errorCode === TELLEGPRE_NUMBER_ERROR) {
            message = `Customer is a Telstra (Siebel) customer who is trying to port into a ${brandName} (Console) plan.`;
            if (brandName === BRANDS.Telstra.name) {
                title = telstraNumberNewErrorTitle;
                preInfo = 'Input error. ';
                postInfo = 'You can’t transfer a Telstra prepaid mobile number.';
            } else {
                title = LegacyErrorTitle;
                preInfo = 'We’re unable to process this request online. Need help? ';
                text = 'Message us';
            }
        } else if (hasError && errorCode === TELSTRAPOST_NUMBER_ERROR) {
            message = 'Customer has entered a Telstra Postpaid (Console) mobile number in the Pre-Paid port flow.';
            if (brandName === BRANDS.Telstra.name) {
                title = telstraNumberErrorTitle;
                preInfo = 'Transferring to the Pre-Paid network? Please ';
                postInfo = ' so we can complete your activation.';
                text = 'Message us';
            } else {
                title = LegacyErrorTitle;
                preInfo = 'We’re unable to process this request online. Need help? ';
                text = 'Message us';
            }
        } else if (hasError && errorCode === TELLEGPOST_NUMBER_ERROR) {
            message = 'Customer has entered a Telstra Postpaid (Siebel) mobile number in the Pre-Paid port flow.';
            if (brandName === BRANDS.Telstra.name) {
                title = telstraNumberErrorTitle;
                preInfo = 'Transferring to the Pre-Paid network? Please ';
                postInfo = ' so we can complete your activation.';
                text = 'Message us';
            } else {
                title = LegacyErrorTitle;
                preInfo = 'We’re unable to process this request online. Need help? ';
                text = 'Message us';
            }
        } else if (hasError && errorCode === BOOSTLEG_NUMBER_ERROR) {
            message = `Customer is a Boost (Siebel) customer who is trying to port into a ${brandName} (Console) plan.`;
            title = LegacyErrorTitle;
            preInfo = 'We’re unable to process this request online. Need help? ';
            text = 'Message us';
        } else if (hasError && errorCode === TEM_NUMBER_ERROR) {
            message = 'Customer has entered a Telstra Enterprise mobile number in the Pre-Paid port flow.';
            if (brandName === BRANDS.Telstra.name) {
                title = telstraNumberErrorTitle;
                preInfo = 'Transferring to the Pre-Paid network? Please  ';
                postInfo = ' so we can complete your activation.';
                text = 'Message us';
            } else {
                title = LegacyErrorTitle;
                preInfo = 'We’re unable to process this request online. Need help? ';
                text = 'Message us';
            }
        } else if (hasError && errorCode === SAMEPRE_NUMBER_ERROR) {
            if (brandName !== BRANDS.Telstra.name) {
                title = `This is a ${brandName} number`;
                preInfo = 'Input error. ';
                postInfo = `You can't transfer a ${brandName} prepaid mobile number.`;
            }
        } else if (hasError && errorCode === NONTPOST_NUMBER_ERROR) {
            const mobileNumberBrand = getBrandNameFromNonTPostStatus(errorHttpStatus);
            title = LegacyErrorTitle;
            message = `Customer has entered a ${mobileNumberBrand} postpaid (Console) mobile number in the Pre-Paid port flow.`;
            preInfo = 'We’re unable to process this request online. Need help? ';
            text = 'Message us';
        }
        return (
            <React.Fragment>
                <div className="modal-header-no-border ppv-header">
                    <img className="porting-lock-image mb-4" src={PortingLock} aria-hidden="true" alt="" />
                    <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={this.closeModal} />
                </div>
                <div className="modal-body-porting">
                    <div className="transfer-number-body">
                        <TextStyle id="verifyNumberHeader" className="mb-2" element={'h2'} alias="HeadingB">
                            {verifyNumbertitle}
                        </TextStyle>
                        <TextStyle className="verify-number-subheading" alias="TextBodyShort">
                            {applyBrandDataInTemplate(verifyNumberPara)}
                        </TextStyle>
                        {hasError && errorCode && (
                            <div id="error-description" className="mt-3" tabIndex="-1">
                                <MessageSection
                                    developmentUrl={ableSpriteSheet}
                                    variant="Error"
                                    titleText={title}
                                    description={
                                        <Fragment>
                                            {Parser(preInfo)}
                                            {text ? <span><span></span><MessageUs errorType="ppv-error" message={message} text={text} /> </span> : ''}
                                            {postInfo ? Parser(postInfo) : null}
                                        </Fragment>
                                    } />
                            </div>
                        )}
                        <form form="verifyNumberForm">
                            <fieldset>
                                <div className="ppv-form-group">
                                    <Field
                                        name="verifyNumber"
                                        id="verifyNumber"
                                        component={Input}
                                        type="text"
                                        label={verifyNumberField.label}
                                        aria-required="true"
                                        maxLength="10"
                                        onChange={this.handleChange}
                                        value={this.state.verifyNumber}
                                        onKeyDown={this.handleEnterClick}
                                    />
                                    <Error className="mb-button" errorDescriptionId="verifyNumber" isValidationError errorText={this.state.errors.verifyNumber} />
                                    <SpinningButton
                                        id="verifyButton"
                                        onSubmit={handleSubmit(this.checkMobileNumber)}
                                        stylingClass="porting-verify-your-number"
                                        isDisabled={isPortingInProgress}
                                        isLoading={isPortingInProgress}
                                        buttonText={buttonLabel}
                                        className="mb-button"
                                    />
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </React.Fragment >
        );
    }

    resendSmsCode(values, dispatch, props) {
        const storeObject = store.getState();
        const { portingNumber = null } = storeObject.welcome;
        const { validatePrePortVerfication } = props.actions;
        this.props.clearFields(false, false, 'otp');
        addDataLayerEventInfo('click', 'clickTrack', 'linkClick', 'Resend');
        validatePrePortVerfication(portingNumber);
        this.disableLink();
    }

    getNewSmsCode(values, dispatch, props) {
        const storeObject = store.getState();
        const { portingNumber = null } = storeObject.welcome;
        const { validatePrePortVerfication } = props.actions;
        this.props.clearFields(false, false, 'otp');
        addDataLayerEventInfo('click', 'clickTrack', 'buttonClick', 'Get a new SMS code');
        validatePrePortVerfication(portingNumber);
        this.disableLink();
    }

    renderVerifyNumber() {
        const { resetPorting } = this.props.actions;
        resetPorting();
        this.changeScreen('verify number');
    }

    closeModal() {
        this.props.change('verifyNumber', '');
        this.props.change('otp', '');
        this.props.toggleModal();
    }

    verifySmsScreen(verifySms) {
        const storeObject = store.getState();
        const {
            portingNumber = null,
            validations: { isVerifySmsInProgress },
            errors: { hasError, errorCode },
            validations: { resendCodeAttempts, completionDate }
        } = storeObject.welcome;
        const {
            formErrors: { incorrectCode, misOrderInProgressError }
        } = this.props;
        const { ppvMessenger, verifySmsTitle, verifySmsResentTitle, verifySmsPara, verifySmsButtonLabel, resendLink, handleSubmit, verifySmsField } = verifySms;
        const parsedPortingNumber = [portingNumber.slice(0, 4), portingNumber.slice(4, 7), portingNumber.slice(7)].join(' ');
        const isResend = resendCodeAttempts > 1;
        updateDataLayerObject(createDataLayerPage('Enter SMS Security Code'));
        const { textMsg, context } = ppvMessenger;
        return (
            <React.Fragment>
                <div className="modal-header-no-border ppv-header-sms">
                    <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={this.closeModal} />
                </div>
                <div className="modal-body-porting">
                    <div className="transfer-number-body">
                        {hasError && errorCode === MIS_ORDER_INPROGRESS ? (
                            <div>
                                <img className="inprogress-err-img" alt="error" src={ErrorIcon} />
                                {/* <h2 className="inprogress-err-heading">{misOrderInProgressError.label}</h2>
                                <p className="inprogress-err-para para1">{misOrderInProgressError.errorPara1}{completionDate}.</p>
                                <p className="inprogress-err-para">{misOrderInProgressError.errorPara2}</p> */}
                                <TextStyle alias="HeadingB" element="h2" className="inprogress-err-heading">
                                    {misOrderInProgressError.label}
                                </TextStyle>
                                <TextStyle alias="TextBigBodyShort" element="p" className="inprogress-err-para para1">
                                    {misOrderInProgressError.errorPara1}
                                    {completionDate}.
                                </TextStyle>
                                <TextStyle alias="TextBigBodyShort" element="p" className="inprogress-err-para">
                                    {misOrderInProgressError.errorPara2}
                                </TextStyle>
                                <ActionButton element="button" onClick={this.closeModal} className="mt-3" variant="MediumEmphasis" label="Try again" />
                            </div>
                        ) : (
                            <div>
                                <TextStyle id="verifyNumberHeader" className="mb-2" element={'h2'} alias="HeadingB">
                                    {isResend ? verifySmsResentTitle : verifySmsTitle}
                                </TextStyle>
                                <TextStyle alias="TextBodyShort"> {`${verifySmsPara}${parsedPortingNumber}`}</TextStyle>

                                {hasError && errorCode === INVALID_CODE_ERROR && (
                                    <div id="error-description" tabIndex="-1">
                                        <MessageSection
                                            developmentUrl={ableSpriteSheet}
                                            variant="Error"
                                            titleText={incorrectCode.label}
                                            description={incorrectCode.errorPara} />
                                    </div>
                                )}
                                <form form="verifySMSForm">
                                    <fieldset>
                                        <div className="ppv-form-group">
                                            <div>
                                                <Field
                                                    name="otp"
                                                    id="otp"
                                                    ppvInputNote={verifySmsField.note}
                                                    onChange={this.handleChange}
                                                    component={Input}
                                                    type="text"
                                                    label={verifySmsField.label}
                                                    aria-required="true"
                                                    maxLength="6"
                                                    value={this.state.otp}
                                                    onKeyDown={this.handleEnterClick}
                                                />
                                                <Error errorDescriptionId="otp" isValidationError errorText={this.state.errors.otp} />
                                                <SpinningButton
                                                    id="smsButton"
                                                    onSubmit={handleSubmit(this.verifySmsCode)}
                                                    stylingClass="porting-verify-sms-code"
                                                    isDisabled={isVerifySmsInProgress}
                                                    isLoading={isVerifySmsInProgress}
                                                    buttonText={verifySmsButtonLabel}
                                                    className="mb-button"
                                                />
                                            </div>
                                            <div className="resend-sms-code mt-4">
                                                <ActionButton element="button" variant="LowEmphasis" disabled={this.state.isResendDisabled} onClick={handleSubmit(this.resendSmsCode)} label={resendLink} />
                                                {isResend && (
                                                    <TextStyle alias="FinePrint">
                                                        {' '}
                                                        {textMsg}
                                                        <MessageUs errorType="ppv-error" message={`${context}${parsedPortingNumber}`} text="message us" />.
                                                    </TextStyle>
                                                )}
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
    smsCheckScreen(smsCheckProps) {
        const { icon, successPara } = smsCheckProps;
        return (
            <React.Fragment>
                <div className="modal-header-no-border ppv-header ppv-message-header" />
                <div className="modal-body-porting">
                    <div className="transfer-number-body">
                        <div className="sms-check">
                            <Message type="success" message="blah" ppvMessage fullPageButtonText="Continue" imgTag={icon}>
                                <h3>{Parser(successPara)}</h3>
                            </Message>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    errorScreen(errorScreenProps) {
        const { errorPara, label } = errorScreenProps;
        // pushErrorContextToLiveChat(label);
        const errorLabel = !isEmpty(label) && label ? label : '';
        const { toggleModal } = this.props;
        return (
            <React.Fragment>
                <div className="modal-header-no-border ppv-header ppv-message-header mb-4 porting-error-header">
                    <img className="porting-error-image" alt="" src={FatalErrorIcon} aria-hidden="true" />
                    <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={toggleModal} />
                </div>
                <div className="modal-body-porting">
                    <div className="transfer-number-body">
                        <div className="error-check">
                            <TextStyle id="errorHeaderLabel" className="porting-fatal-error mb-4" element={'h2'} alias="HeadingB">
                                {Parser(errorLabel)}
                            </TextStyle>
                            <TextStyle alias="TextBodyShort" className="mb-5">
                                {Parser(errorPara)}
                            </TextStyle>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    codeExpiredErrorScreen(errorScreenProps) {
        const { errorPara, label, buttonText, handleSubmit } = errorScreenProps;
        const storeObject = store.getState();
        const { toggleModal } = this.props;
        const {
            validations: { isPortingInProgress }
        } = storeObject.welcome;
        return (
            <React.Fragment>
                <div className="modal-header-no-border ppv-header ppv-message-header mb-4">
                    <img className="porting-lock-image mb-4" alt="" src={ErrorIcon} aria-hidden="true" />
                    <IconButton icon="Close" className="modal-close-btn" developmentUrl={ableSpriteSheet} onClick={toggleModal} />
                </div>
                <div className="modal-body-porting">
                    <div className="transfer-number-body">
                        <div className="code-expire-error">
                            <div className="warning full-page-error">
                                <TextStyle id="errorHeaderLabel" className="mb-4" element={'h2'} alias="HeadingB">
                                    {Parser(label)}
                                </TextStyle>
                                <TextStyle alias="TextBodyShort" className="mb-4">
                                    {Parser(errorPara)}
                                </TextStyle>
                                <div className="button-box">
                                    <SpinningButton
                                        onSubmit={handleSubmit(this.getNewSmsCode)}
                                        stylingClass="porting-get-sms-code"
                                        isDisabled={isPortingInProgress}
                                        isLoading={isPortingInProgress}
                                        buttonText={buttonText}
                                        className="mb-button"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    resendSmsScreen(resendSmsScreenProps) {
        setTimeout(() => {
            this.setState({
                showScreen: 'verify sms'
            });
        }, 9000);
        return (
            <React.Fragment>
                <div className="modal-header-no-border ppv-header ppv-message-header" />
                <div className="modal-body-porting">
                    <div className="transfer-number-body">
                        <div role="alert" aria-busy="true" className="resend-sms">
                            <Spinner label="sending your code" />
                            <div className="resend-sms-para">
                                <TextStyle element="p" alias="TextBigBodyLong">{Parser(resendSmsScreenProps)}</TextStyle>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TransferNumber;
