import React from 'react';
import './index.css';
import { Spacing } from '@able/react';

export function ConnectIdVerifiedBadge({ className = '' }) {
    return (
        <Spacing top="spacing1x" bottom="spacing1x" left="spacing1nHalf" right="spacing1nHalf" className={['connectid-verified-badge', className]}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>ConnectID logo</title>
                <path d="M17.6275 13.9316C17.6275 16.9582 15.1075 19.4068 11.9928 19.4068C8.878 19.4068 6.35801 16.9582 6.35801 13.9316C6.35801 10.9049 8.878 8.45627 11.9928 8.45627C13.558 8.45627 14.9667 9.06464 15.9841 10.0684L22.3545 3.87833C21.3371 2.88973 20.711 1.52091 20.711 0H15.9841C15.9841 2.03802 16.6101 3.95437 17.6901 5.53612C16.0623 4.48669 14.0902 3.87833 11.9928 3.87833C6.2641 3.86312 1.63107 8.36502 1.63107 13.9316C1.63107 19.4981 6.2641 24 11.9928 24C17.7214 24 22.3545 19.4981 22.3545 13.9316H17.6275Z" fill="#262626" />
            </svg>
            <Spacing className={['connectid-verified-badge--text']} left="spacingHalf">Verified with ConnectID</Spacing>
        </Spacing>
    );
}