import './youriddetails.css';
import * as actionCreators from './module';
import * as idvActionCreators from './connectIdv.module';
import View from './presenter';
import ThirdPartyIntegration from './connectIdv.presenter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { validateDocumentDetails, validateCustomerDetails, validatePortingDetails } from '../app/actions';
import { updateStepperCustomData } from '../../components/Progress/module';
import { isIdvModuleThirdParty } from '../../adapters/utils';

const nativeIdvState2Props = state => ({
    appData: state.app.appData,
    loading: state.yourIdDetails.loading,
    locale: state.yourIdDetails.locale,
    bannerImage: state.yourIdDetails.bannerImage,
    errors: state.yourIdDetails.errors,
    loggedInCustomerData: state.app.loggedInCustomerData,
    hasErrorOccurred: state.app.hasErrorOccurred,
    customerDataFetchSuccess: state.app.customerDataFetchSuccess
});
const nativeIdvDispatch2Props = dispatch => ({
    actions: bindActionCreators(Object.assign({}, actionCreators, {
        validateDocumentDetails,
        validateCustomerDetails,
        validatePortingDetails,
        updateStepperCustomData
    }), dispatch)
});

const thirdPartyIdvState2Props = state => ({
    appData: state.app.appData,
    loading: state.yourIdDetails.loading,
    idVerificationInProgress: state.yourIdDetails.idVerificationInProgress,
    userIdVerified: state.yourIdDetails.userIdVerified,
    magicUrlApiMeta: state.yourIdDetails.magicUrlApiMeta,
    idvDataApiMeta: state.yourIdDetails.idvDataApiMeta,
    isSoftStopError: state.yourIdDetails.isSoftStopError,
    digitalDataSnapshot: state.yourIdDetails.digitalDataSnapshot,
    loggedInCustomerData: state.app.loggedInCustomerData,
    customerDataFetchSuccess: state.app.customerDataFetchSuccess
});
const thirdPartyIdvDispatch2Props = dispatch => ({
    actions: bindActionCreators(Object.assign({}, idvActionCreators, {
        updateStepperCustomData
    }), dispatch)
});

export default connect(
    state => {
        const thirdparty = isIdvModuleThirdParty();
        return (thirdparty ? thirdPartyIdvState2Props : nativeIdvState2Props)(state);
    },
    dispatch => {
        const thirdparty = isIdvModuleThirdParty();
        return (thirdparty ? thirdPartyIdvDispatch2Props : nativeIdvDispatch2Props)(dispatch);
    }
)(props => isIdvModuleThirdParty() ? <ThirdPartyIntegration {...props} /> : <View {...props} />);
