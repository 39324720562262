import React, { useEffect, useState, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { MessageInline, MessageSection, TextStyle } from '@able/react';
import FieldError from '../../../../common/components/fieldError/fieldError';
import FieldText from '../../../../common/components/fieldText/fieldText';
import CONTACT_PAGE_CONSTANTS from '../../constants';
import { trim } from '../../../../common/utils/userDetails';
import {
    getPhoneNumber,
    getContactEmail,
    getAddressLine1,
    getAddressLine2,
    getAddressLine3,
    getLocalityName,
    getCustomerState,
    getPostCode,
    updateStepperCustomData,
    getLevelOfAuthentication,
    getSelectedBusiness,
    getSelectedBusinessDetails,
    getSubmitSMBAccountMeta,
    getAddressId,
    getAddressSMBVerificationMeta,
    getUpdateBusinessContactDetailsErrorState
} from './ducks';
import { updateEmailSuccess } from '../updateEmail/ducks';
import { updateMobileSuccess, setSelectedBusinessError, verifyBusinessAddress, createSMBAccount, setUpdateBusinessContactsFormErrors, onEditContactFormSubmitted } from '../../module';
import { focusOnElement, formatMobileNumber, getAbleSpriteSheet, getFormValues, isSelectedDeviceMBB, nextPage } from '../../../../adapters/utils';
import { getContactDetailsErrors, getUpdateBusinessContactDetailsErrors, hasContactDetailsErrors } from './editContactDetailsUtils';
import { mfaLogin, flushMfaFromEditFlag } from '../../../../adapters/authentication-utils';
import UpdateEmail from '../updateEmail/updateEmail';
import UpdateAddress from '../updateAddress/updateAddress';
import UpdateContactModal from '../../updateContactModal';
import Modal from 'react-modal';
import { addDataLayerEventInfo, addMultiDataLayerUser } from '../../../../adapters/analytics-utils';
import { userProfileInfoMeta } from '../../../../adapters/constants';
import './editContactDetails.css';
import { NONPHYSICAL_ADDRESS_ERROR } from '../../../../adapters/errorCode';
import { AbleCheckBox } from '../../../../components/form/AbleCheckBox';
import { BusinessRegistration } from '../businessRegistration/businessRegistration';
import SelectBusiness from '../selectBusiness/selectBusiness';
import { ActionButton } from '@able/react';
import SpinningButton from '../../../../components/SpinningButton/SpinningButton';
import { formValueSelector } from 'redux-form';
import BusinessRegistrationReadOnly from '../businessRegistrationReadOnly/businessRegistrationReadOnly';
import { LimitReachedSoftStopError } from '../limitReachedSoftStopError';
import IdVerifiedMessage from '../idVerifiedMessage';

const { pageTitle, SoleTraderCheck } = CONTACT_PAGE_CONSTANTS;
const ableSpriteSheet = getAbleSpriteSheet();

const EditContactDetails = ({
    bannerImage,
    contactEmail,
    phoneNumber,
    addressline1,
    addressline2,
    addressline3,
    localityName,
    customerState,
    postCode,
    updateStepperCustomDataAction,
    updatePhoneProps,
    levelOfAuthentication,
    isMfafromEdit,
    editingField,
    updateMobileSuccessAction,
    updateEmailSuccessAction,
    validateBusinessRegistrationForm,
    updateMobileStatus,
    selectedBusiness,
    validateBusinessContactsForm,
    businessContactSameAsPersonalContact,
    selectedBusinessDetails = {},
    submitSMBAccountMeta = {},
    addressVerificationMeta = {},
    verifyBusinessAddressAction,
    createSMBAccountAction,
    isBackNavigation,
    onEditContactFormSubmittedAction,
    soleTraderCheck = false,
    showIdVerifiedMessage,
    personalAddressId,
    setSelectedBusinessErrorAction,
    updateBusinessContactsFormErrors,
    setUpdateBusinessContactsFormErrorsAction,
    isMBBActivation,
    phoneNumVerificationLimitInfo,
    msisdn,
    isPortingFlow,
    isEsimActivation,
    portingNumber,
    userIdVerified
}) => {
    const [errors, setErrors] = useState({
        address: false,
        phoneNumber: false,
        contactEmail: false
    });

    const [isUpdateEmailOpen, setIsUpdateEmailOpen] = useState(false);
    const [isMfaOpen, setIsMfaOpen] = useState(true);
    const [isEmailFirstStep, setIsEmailFirstStep] = useState(true);
    const [isUpdateAddressOpen, setIsUpdateAddressOpen] = useState(false);
    const [isUpdatePhoneOpen, setIsUpdatePhoneOpen] = useState(false);
    const [isToastOpen, setIsToastOpen] = useState(true);
    const [showSoleTraderForm, setSoleTraderForm] = useState(soleTraderCheck);
    const otherEmail = useRef(null);
    const modal1 = useRef(null);
    const modal2 = useRef(null);
    const modal3 = useRef(null);
    const modal4 = useRef(null);
    const modal5 = useRef(null);
    const modal6 = useRef(null);
    const modal7 = useRef(null);
    const modal8 = useRef(null);
    const prevAddressLine1 = useRef(addressline1);

    useEffect(() => {
        return () => {
            isMfafromEdit && flushMfaFromEditFlag();
        };
    }, []);

    useEffect(() => {
        if (
            selectedBusiness && selectedBusiness !== 'createNew'
            && updateBusinessContactsFormErrors !== undefined
        ) {
            validateUpdateBusinessContactDetailsForm();
        }
    }, [selectedBusinessDetails]);

    const {
        errors: { hasError, errorCode },
        change,
        appData: {
            customer: { dvsCheckRequired }
        }
    } = updatePhoneProps;
    const { didUserUpdateMobile, diffMobileUpdated, didUserUpdateEmail, didUserUpdateAddress, updateCounter } = updateMobileStatus;

    let toastMessage;
    if (diffMobileUpdated) {
        toastMessage = 'Your mobile number has been updated.';
    } else if (didUserUpdateEmail) {
        toastMessage = 'Your email address has been updated.';
    } else if (didUserUpdateMobile) {
        toastMessage = 'Your mobile number will be updated when your SIM activation is complete.';
    } else if (didUserUpdateAddress) {
        toastMessage = 'Your address has been updated.';
    }

    const isMobileNumberMandatory = !isMBBActivation || !contactEmail;

    const verifyBusinessAddress = addressLine1 => new Promise(resolve =>
        verifyBusinessAddressAction({ addressLine1 }, resolve));

    const onClickContinue = async () => {
        addDataLayerEventInfo('click', 'clickTrack', 'buttonClick', 'Continue');
        const e = getContactDetailsErrors({ phoneNumber, contactEmail, addressline1 }, { isMobileNumberMandatory });

        if (!hasContactDetailsErrors(e)) {
            if (showSoleTraderForm && !selectedBusiness) {
                setSelectedBusinessErrorAction('Required selection. Please select an account.');
                focusOnElement('#selectedBusinessLegend', 0);
                addDataLayerEventInfo('error', 'Inline', 'unavailable', 'Required selection. Please select an account.');
                return;
            }
            if (showSoleTraderForm && selectedBusiness === 'createNew') {
                if (!validateBusinessRegistrationForm().valid) return;
                else if (!(await validateBusinessContactsForm()).valid) return;
            } else if (showSoleTraderForm && selectedBusiness && selectedBusiness !== 'createNew') {
                if (!validateUpdateBusinessContactDetailsForm().valid) {
                    return false;
                }
            }
            if (showSoleTraderForm) {
                addDataLayerEventInfo('click', 'clickTrack', 'optInCheckbox', 'This service will be for business use');
                const eventArr = [
                    { type: userProfileInfoMeta.SOURCE_SYSTEM, value: 'Eridxp' },
                    { type: userProfileInfoMeta.USER_TYPE, value: 'Prepaid' }
                ];
                addMultiDataLayerUser(eventArr);
                if (submitSMBAccountMeta.submitted) {
                    return nextPage(updateStepperCustomDataAction);
                }
                const {
                    abn = null,
                    companyName: businessName = null,
                    businessEmail = null,
                    businessAddress = null,
                    businessPhone: businessPhoneNumber = null,
                    selectedBusiness = null
                } = getFormValues();

                const payload = {
                    businessPhoneNumber,
                    abn,
                    acn: null,
                    businessEmail,
                    businessName,
                    accountNumber: null,
                    businessAddress
                };
                if (SelectBusiness && selectedBusiness !== 'createNew') {
                    payload.businessEmail = null;
                    payload.businessPhoneNumber = null;
                    payload.businessAddress = null;
                    payload.businessName = selectedBusinessDetails.businessName;
                    payload.accountNumber = selectedBusinessDetails.accountNumber;
                    payload.abn = selectedBusinessDetails.abn;
                    payload.acn = selectedBusinessDetails.acn;
                }
                if (businessContactSameAsPersonalContact) {
                    payload.businessAddress = personalAddressId;
                    payload.businessEmail = contactEmail;
                }

                if (selectedBusiness === 'createNew' && !businessContactSameAsPersonalContact) {
                    const verifiedAddress = await verifyBusinessAddress(businessAddress);
                    if (verifiedAddress && !verifiedAddress.hasError && verifiedAddress.data && verifiedAddress.data.addressId) {
                        payload.businessAddress = verifiedAddress.data.addressId;
                    } else return;
                }
                const isNewAccountCreation = selectedBusiness === 'createNew';
                createSMBAccountAction(payload, businessContactSameAsPersonalContact, isNewAccountCreation, ({ success }) => {
                    if (success) {
                        nextPage(updateStepperCustomDataAction);
                        onEditContactFormSubmittedAction();
                    }
                });
            } else {
                nextPage(updateStepperCustomDataAction);
                onEditContactFormSubmittedAction();
            }
        } else {
            setErrors(e);
        }
    };

    const validateUpdateBusinessContactDetailsForm = () => {
        const errors = getUpdateBusinessContactDetailsErrors(selectedBusinessDetails, businessContactSameAsPersonalContact);
        setUpdateBusinessContactsFormErrorsAction(errors);
        return {
            valid: !Object.keys(errors).length
        };
    };

    const setSoleTraderFormVisibility = (value) => {
        setSoleTraderForm(value);
        change('soleTraderCheck', value);
    };

    const toggleSoleTraderForm = () => {
        if (showSoleTraderForm) setSoleTraderFormVisibility(false);
        else {
            const e = getContactDetailsErrors({ phoneNumber, contactEmail, addressline1 }, { isMobileNumberMandatory });
            if (hasContactDetailsErrors(e)) {
                setErrors(e);
                setSoleTraderFormVisibility(false);
            } else setSoleTraderFormVisibility(true);
        }
    };

    const checkLevelOfAuthentication = (loa, editingField) => {
        if (loa === 'LOA2') {
            if (editingField === 'email') toggleUpdateEmailModal();
            else if (editingField === 'phone') toggleUpdatePhoneModal();
            else if (editingField === 'address') toggleUpdateAddressModal();
        } else {
            mfaLogin(loa, editingField);
        }
    };

    const closeMfaModal = () => {
        setIsMfaOpen(false);
        updatePhoneProps.actions.resetEditContactModal();
    };

    const onContinueEmailModal = (email) => {
        otherEmail.current = email;
        setIsMfaOpen(false);
        updatePhoneProps.actions.resetEditContactModal();
        setIsUpdateEmailOpen(true);
        setIsEmailFirstStep(false);
    };

    const closeUpdateEmailModal = () => {
        setIsUpdateEmailOpen(false);
        setIsEmailFirstStep(true);
        setIsToastOpen(true);
        updatePhoneProps.actions.resetEditContactModal();
    };

    const toggleUpdateEmailModal = () => {
        setIsUpdateEmailOpen(state => !state);
        setIsEmailFirstStep(true);
        setIsToastOpen(true);
        updatePhoneProps.actions.resetEditContactModal();
    };

    const toggleUpdateAddressModal = () => {
        setIsUpdateAddressOpen(state => !state);
        setIsToastOpen(true);
        updatePhoneProps.actions.resetEditContactModal();
    };

    const toggleUpdatePhoneModal = () => {
        setIsUpdatePhoneOpen(state => !state);
        setIsToastOpen(true);
        updatePhoneProps.actions.resetEditContactModal();
        updatePhoneProps.actions.resetOTC();
    };

    const closeUpdatePhoneModal = () => {
        setIsUpdatePhoneOpen(false);
        setIsToastOpen(true);
        updatePhoneProps.actions.resetEditContactModal();
        updatePhoneProps.actions.resetOTC();
    };

    const showToastMsg = diffMobileUpdated || didUserUpdateEmail || didUserUpdateAddress || didUserUpdateMobile;

    useEffect(() => {
        const prevAddress = prevAddressLine1.current;
        if (addressline1 !== prevAddress) {
            if (!prevAddress) {
                addDataLayerEventInfo('impression', 'addAddress', 'newAddress', 'newAddress');
            } else {
                addDataLayerEventInfo('impression', 'updateAddress', 'existingAddress', 'existingAddress');
            }
        }
    }, [updateCounter, addressline1]);

    useEffect(() => {
        if (addressline1) {
            const err = getContactDetailsErrors({ phoneNumber, contactEmail, addressline1 }, { isMobileNumberMandatory });
            if (err.address) {
                setErrors({ ...errors, address: true });
            } else setErrors({ ...errors, address: false });
        }
    }, [addressline1]);

    let submittingForm = false;
    if (
        submitSMBAccountMeta.submitting
        || (selectedBusiness === 'createNew' && addressVerificationMeta.verifying)
    ) submittingForm = true;


    if (!phoneNumber && isMobileNumberMandatory && phoneNumVerificationLimitInfo.limitReached) {

        const updateContactMedium = () => {
            updateMobileSuccessAction({
                phoneNumber: isPortingFlow ? portingNumber : isEsimActivation ? `The mobile number you're activating now` : msisdn
            });
        }
        return <LimitReachedSoftStopError
            errorDetails={phoneNumVerificationLimitInfo.errorDetails}
            buttonText="Add activating number"
            onContinue={updateContactMedium}
        />;
    }

    return (
        <div className="container row">
            <div className="d-flex flex-column edit-contact-details col-md-5">
                <div className="edit-contact-details-item edit-contact-details-toast">
                    <div aria-live="polite" aria-atomic="true" id="ycd-ariaLive-slot1">
                        <div id="contact-details-toast-1">
                            {!showToastMsg && !isBackNavigation && dvsCheckRequired && isToastOpen && showIdVerifiedMessage && userIdVerified && <IdVerifiedMessage className="mbm" />}
                        </div>
                    </div>
                    <div aria-live="polite" aria-atomic="true" tabIndex="-1">
                        {showToastMsg && isToastOpen && <MessageSection developmentUrl={ableSpriteSheet} variant="Success" description={toastMessage} className="mbm" />}
                    </div>
                </div>
                <div className="edit-contact-details-item edit-contact-details-heading">
                    <TextStyle element={'h1'} tabindex="-1" alias="HeadingA">{pageTitle}</TextStyle>
                </div>
                {/* {levelOfAuthentication !== 'LOA2' &&  */}
                <div className="edit-contact-details-item edit-contact-details-alert-bar">
                    {contactEmail && addressline1 && phoneNumber && <MessageInline developmentUrl={ableSpriteSheet} className="mt-4 mb-2" variant="Information" description="Please review and update your contact details where applicable. We may need to verify your access." />}
                    {(!contactEmail || !addressline1 || !phoneNumber) && <MessageInline developmentUrl={ableSpriteSheet} className="mt-4 mb-2" variant="Information" description="Please review and update your missing contact details. We may need to verify your access." />}
                </div>
                <div className="edit-contact-details-item my-2">
                    <TextStyle alias="Label">
                        Email address
                    </TextStyle>
                    {contactEmail && <TextStyle alias='TextBodyShort'>{contactEmail}</TextStyle>}
                    {!contactEmail && !phoneNumber && (
                        <FieldError showIcon={false}>
                            Your email address needs to be added.
                        </FieldError>
                    )}
                    {!contactEmail && phoneNumber && (
                        <FieldText showIcon={false}>
                            No email address on your customer record.
                        </FieldText>
                    )}
                    <ActionButton
                        element="button"
                        variant="LowEmphasis"
                        disabled={submittingForm}
                        label={contactEmail ? 'Update email address' : 'Add email address'}
                        onClick={() => checkLevelOfAuthentication(levelOfAuthentication, 'email')}
                    />
                    {errors.contactEmail && <FieldError>Required. Please add your email address.</FieldError>}
                </div>
                <div className="edit-contact-details-item mb-2">
                    <TextStyle alias="Label">Address</TextStyle>
                    {addressline1 && (
                        <TextStyle alias="TextBodyShort">
                            {addressline1 && trim(addressline1)}
                            {addressline2 && ', ' + trim(addressline2)}
                            {addressline3 && ', ' + trim(addressline3)}
                            {localityName && ', ' + trim(localityName)}
                            {customerState && ', ' + trim(customerState)}
                            {postCode && ', ' + postCode}
                        </TextStyle>
                    )}
                    {!addressline1 && (
                        <FieldError showIcon={false}>
                            Your address needs to be added.
                        </FieldError>
                    )}
                    <ActionButton
                        element="button"
                        variant="LowEmphasis"
                        disabled={submittingForm}
                        label={addressline1 ? 'Update Address' : 'Add Address'}
                        onClick={() => checkLevelOfAuthentication(levelOfAuthentication, 'address')} />
                    {errors.address && <FieldError>Required. Please add your address.</FieldError>}
                </div>
                <div className="edit-contact-details-item mb-3">
                    <div aria-live="polite" aria-atomic="true">
                        {!phoneNumber && isMobileNumberMandatory && errors.phoneNumber &&
                            <MessageInline
                                className="mb-3"
                                developmentUrl={ableSpriteSheet}
                                variant="Error"
                                description="Required field. Please add a mobile number." />}
                    </div>
                    <TextStyle alias="Label">
                        Mobile number
                    </TextStyle>
                    {phoneNumber && (
                        <TextStyle alias='TextBodyShort'>
                            {formatMobileNumber(phoneNumber || '')}
                        </TextStyle>
                    )}
                    {!phoneNumber && (
                        isMobileNumberMandatory
                            ? <FieldError showIcon={false}>
                                Your mobile number needs to be added.
                            </FieldError>
                            : <FieldText showIcon={false}>
                                Your mobile number is missing. You can add it now.
                            </FieldText>
                    )}
                    <ActionButton
                        element="button"
                        disabled={submittingForm}
                        variant="LowEmphasis"
                        label={phoneNumber ? 'Update mobile number' : 'Add mobile number'}
                        onClick={() => checkLevelOfAuthentication(levelOfAuthentication, 'phone')}
                    />
                </div>

                <fieldset className="contact-details-business-registration" disabled={submittingForm}>
                    {!isBackNavigation &&
                        <AbleCheckBox
                            name="soleTraderCheck"
                            checked={showSoleTraderForm}
                            id="smbCheckbox"
                            className="mb-2"
                            label={SoleTraderCheck.label}
                            onChange={toggleSoleTraderForm}
                        />
                    }
                    <div className="sr-only" aria-atomic="true" aria-live="polite" id="ycd-ariaLive-slot2" />
                    {showSoleTraderForm && (
                        <Fragment>
                            {submitSMBAccountMeta.submitted
                                ? <BusinessRegistrationReadOnly {...selectedBusinessDetails} businessName={selectedBusinessDetails.accountName} legalName={selectedBusinessDetails.businessName} />
                                : <SelectBusiness />}
                            {selectedBusiness && <BusinessRegistration selectedExistingBusiness={selectedBusiness !== 'createNew'} />}
                        </Fragment>
                    )}
                </fieldset>

                <div className="edit-contact-details-item edit-contact-details-continue-button">
                    <SpinningButton
                        stylingClass="mob-button"
                        onSubmit={onClickContinue}
                        isDisabled={submittingForm}
                        isLoading={submittingForm}
                        buttonText="Continue"
                    />
                </div>
            </div>
            <div className="col-md-5">{bannerImage && <img className="contact-details-image hide-in-mobile" alt="" src={bannerImage} />}</div>

            {/* auto open modals */}
            {isMfafromEdit && levelOfAuthentication === 'LOA2' && (
                <React.Fragment>
                    {editingField === 'email' && (
                        <Modal
                            parentSelector={() => document.querySelector('#modal-ppv')}
                            shouldCloseOnOverlayClick={false}
                            isOpen={isMfaOpen}
                            className="modal-dialog modal-lg ppv-modal"
                            contentLabel="Enter new email address"
                            aria={{ labelledby: 'enter-email-modal-heading-1', modal: 'true', role: 'dialog' }}
                            onRequestClose={closeMfaModal}
                            shouldCloseOnEsc={true}
                            ref={modal1}
                        >
                            <UpdateEmail readerId="enter-email-modal-heading-1" modalRef={modal1} onClose={closeMfaModal} onContinue={onContinueEmailModal} />
                        </Modal>
                    )}
                    {editingField === 'phone' && (
                        <Modal
                            parentSelector={() => document.querySelector('#modal-ppv')}
                            shouldCloseOnOverlayClick={false}
                            isOpen={isMfaOpen}
                            className="modal-dialog modal-lg ppv-modal"
                            contentLabel="Confirm mobile number"
                            aria={{ labelledby: 'update-number-modal-heading-1', modal: 'true', role: 'dialog' }}
                            onRequestClose={closeMfaModal}
                            shouldCloseOnEsc={true}
                            ref={modal2}
                        >
                            <UpdateContactModal readerId="update-number-modal-heading-1" modalRef={modal2} toggleModal={closeMfaModal} {...updatePhoneProps} updateSuccessAction={updateMobileSuccessAction} />
                        </Modal>
                    )}
                    {editingField === 'address' && (
                        <Modal
                            parentSelector={() => document.querySelector('#modal-ppv')}
                            shouldCloseOnOverlayClick={false}
                            isOpen={isMfaOpen}
                            className="modal-dialog modal-lg ppv-modal"
                            contentLabel="Update address"
                            aria={{ labelledby: 'enter-address-modal-heading-1', modal: 'true', role: 'dialog' }}
                            onRequestClose={closeMfaModal}
                            shouldCloseOnEsc={true}
                            ref={modal3}
                        >
                            <UpdateAddress readerId="enter-address-modal-heading-1" modalRef={modal3} onClose={closeMfaModal} />
                        </Modal>
                    )}
                </React.Fragment>
            )}

            {/* manual open modals */}
            {levelOfAuthentication === 'LOA2' && (
                <React.Fragment>
                    <Modal
                        parentSelector={() => document.querySelector('#modal-ppv')}
                        shouldCloseOnOverlayClick={false}
                        isOpen={isUpdateEmailOpen && isEmailFirstStep}
                        className="modal-dialog modal-lg ppv-modal"
                        contentLabel="Enter new email address"
                        aria={{ labelledby: 'enter-email-modal-heading', modal: 'true', role: 'dialog' }}
                        onRequestClose={closeUpdateEmailModal}
                        shouldCloseOnEsc={true}
                        ref={modal4}
                    >
                        <UpdateEmail readerId="enter-email-modal-heading" modalRef={modal4} onClose={closeUpdateEmailModal} onContinue={onContinueEmailModal} />
                    </Modal>
                    <Modal
                        parentSelector={() => document.querySelector('#modal-ppv')}
                        shouldCloseOnOverlayClick={false}
                        isOpen={isUpdateEmailOpen && !isEmailFirstStep}
                        className="modal-dialog modal-lg ppv-modal"
                        onRequestClose={closeUpdateEmailModal}
                        shouldCloseOnEsc={true}
                        aria={{ labelledby: 'update-email-modal-heading', modal: 'true', role: 'dialog' }}
                        ref={modal5}
                    >
                        <UpdateContactModal
                            readerId="update-email-modal-heading"
                            modalRef={modal5}
                            toggleModal={closeUpdateEmailModal}
                            {...updatePhoneProps}
                            otherEmail={otherEmail.current}
                            updateSuccessAction={updateEmailSuccessAction}
                        />
                    </Modal>
                    <Modal
                        parentSelector={() => document.querySelector('#modal-ppv')}
                        shouldCloseOnOverlayClick={false}
                        isOpen={isUpdateAddressOpen && (hasError ? errorCode === NONPHYSICAL_ADDRESS_ERROR : true)}
                        className="modal-dialog modal-lg ppv-modal"
                        contentLabel="Update address"
                        aria={{ labelledby: 'enter-address-modal-heading', modal: 'true', role: 'dialog' }}
                        onRequestClose={toggleUpdateAddressModal}
                        shouldCloseOnEsc={true}
                        ref={modal6}
                    >
                        <UpdateAddress readerId="enter-address-modal-heading" modalRef={modal6} onClose={toggleUpdateAddressModal} />
                    </Modal>
                    <Modal
                        parentSelector={() => document.querySelector('#modal-ppv')}
                        shouldCloseOnOverlayClick={false}
                        isOpen={isUpdateAddressOpen && (hasError && errorCode !== NONPHYSICAL_ADDRESS_ERROR)}
                        className="modal-dialog modal-lg ppv-modal"
                        contentLabel="Update address"
                        onRequestClose={toggleUpdateAddressModal}
                        shouldCloseOnEsc={true}
                        aria={{ labelledby: 'update-address-modal-heading', modal: 'true', role: 'dialog' }}
                        ref={modal7}
                    >
                        <UpdateContactModal readerId="update-address-modal-heading" modalRef={modal7} toggleModal={toggleUpdateAddressModal} {...updatePhoneProps} />
                    </Modal>
                    <Modal
                        parentSelector={() => document.querySelector('#modal-ppv')}
                        shouldCloseOnOverlayClick={false}
                        isOpen={isUpdatePhoneOpen}
                        className="modal-dialog modal-lg ppv-modal"
                        contentLabel="Confirm mobile number"
                        onRequestClose={closeUpdatePhoneModal}
                        shouldCloseOnEsc={true}
                        aria={{ labelledby: 'update-number-modal-heading', modal: 'true', role: 'dialog' }}
                        ref={modal8}
                    >
                        <UpdateContactModal readerId="update-number-modal-heading" modalRef={modal8} toggleModal={closeUpdatePhoneModal} {...updatePhoneProps} updateSuccessAction={updateMobileSuccessAction} />
                    </Modal>
                </React.Fragment>
            )}
        </div>
    );
};

export default connect(
    (state) => ({
        phoneNumber: getPhoneNumber(state),
        contactEmail: getContactEmail(state),
        personalAddressId: getAddressId(state),
        addressline1: getAddressLine1(state),
        addressline2: getAddressLine2(state),
        addressline3: getAddressLine3(state),
        localityName: getLocalityName(state),
        customerState: getCustomerState(state),
        isPortingFlow: state.app.appData.uiState.selectedActivationFlow === 'portNumber',
        portingNumber: state.welcome.portingNumber,
        msisdn: state.app.appData.msisdn,
        isEsimActivation: state.app.appData.uiState.isEsimActivation,
        phoneNumVerificationLimitInfo: state.yourContactDetails.phoneNumVerificationLimitInfo,
        postCode: getPostCode(state),
        levelOfAuthentication: getLevelOfAuthentication(state),
        selectedBusiness: getSelectedBusiness(state),
        selectedBusinessDetails: getSelectedBusinessDetails(state),
        submitSMBAccountMeta: getSubmitSMBAccountMeta(state),
        isBackNavigation: state.yourContactDetails.editContactDetailsFormSubmitted,
        isMBBActivation: isSelectedDeviceMBB(state.app.appData.uiState.selectedDevice),
        addressVerificationMeta: getAddressSMBVerificationMeta(state),
        updateBusinessContactsFormErrors: getUpdateBusinessContactDetailsErrorState(state),
        soleTraderCheck: formValueSelector('yourContactDetailsForm')(state, 'soleTraderCheck'),
        businessContactSameAsPersonalContact: formValueSelector('businessRegistrationForm')(state, 'businessContactSameAsPersonalContact')
    }),
    {
        updateStepperCustomDataAction: updateStepperCustomData,
        updateEmailSuccessAction: updateEmailSuccess,
        updateMobileSuccessAction: updateMobileSuccess,
        verifyBusinessAddressAction: verifyBusinessAddress,
        createSMBAccountAction: createSMBAccount,
        setSelectedBusinessErrorAction: setSelectedBusinessError,
        onEditContactFormSubmittedAction: onEditContactFormSubmitted,
        setUpdateBusinessContactsFormErrorsAction: setUpdateBusinessContactsFormErrors
    }
)(EditContactDetails);
