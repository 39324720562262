import React from 'react';
import classNames from 'classnames';
import { Spinner } from '../Spinner';
import './SpinningButton.css';

export const SpinningButton = ({
    stylingClass = '',
    type = 'button',
    id = 'spinning-able-button',
    ...props
}) => {

    let variantClass;
    switch (props.variant) {
        case 'MediumEmphasis':
            variantClass = 'medium-emphasis';
            break;
        default:
            variantClass = 'high-emphasis';
            break;
    }

    return (
        <div className="SpinningButton-container">
            <button
                className={classNames(
                    { spinning: props.isLoading && props.isDisabled },
                    stylingClass,
                    `able-ActionButton able-ActionButton--${variantClass}`
                )}
                id={id}
                type={type}
                onClick={props.onSubmit}
                disabled={props.isDisabled}
            >
                {props.isLoading && props.isDisabled ? <Spinner className="spinning-btn-spinner" label="loading" /> : props.buttonText}
            </button>
        </div>
    );
};

export default SpinningButton;